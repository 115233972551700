/**
 * Configuration
 */
// const root = !window.location.hostname.includes('localhost') ? '//api.' + window.location.hostname + '/api/v1/' : 'https://api.aircraftupgrade.com/api/v1/'
export const root = !window.location.hostname.includes('localhost') ? '//api.' + window.location.hostname + '/api/v1/' : '//localhost:8001/api/v1/'
export default {
  api: {
    debounce: {
      authorization: 1000
    },
    root,
    token: 'aircraft-token'
  },
  breakpoints: {
    xs: 0,
    sm: 320,
    md: 768,
    lg: 992,
    xl: 1200
  },
  redux: {
    logger: false
  }
};
